import React from 'react';
import useScreenSize from './useScreenSize';

export default function useDeviceDetect() {
  const [isMobile, setMobile] = React.useState(false);
  const screenSize = useScreenSize();

  React.useEffect(() => {
    const userAgent =
      typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
    const mobile =
      Boolean(
        userAgent.match(
          /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
        )
      ) || screenSize.width < 600;
    setMobile(mobile);
  }, [screenSize]);

  return { isMobile };
}
