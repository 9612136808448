import useDeviceDetect from './hooks/useDeviceDetect';
import useScreenSize from './hooks/useScreenSize';
import logo from './logo.jpeg';
import Toany from './ToanyLe.png';
function App() {
  const copyright = new Date().getFullYear();
  const screenSize = useScreenSize();
  const width = screenSize.width;
  const { isMobile } = useDeviceDetect();
  return (
    <div>
      <div
        style={{
          width: width < 1300 ? '80%' : '50%',
          margin: '0 auto',
          textAlign: 'center',
        }}
      >
        <header
          style={{
            padding: '25px 0',

            borderBottom: '1px solid #E4B16F',
            marginBottom: 20,
          }}
        >
          <p
            style={{
              color: '#E4B16F',
              fontWeight: 'bold',
              margin: 0,
              fontSize: '2rem',
            }}
          >
            Welcome!
          </p>
          <h1
            style={{
              position: 'absolute',
              left: '-10000px',
              top: 'auto',
              width: 1,
              height: 1,
              overflow: 'hidden',
              margin: 0,
            }}
          >
            1st Class Hair Studio
          </h1>
        </header>
        <div style={{ backgroundColor: '#fff', minHeight: 500, padding: 15 }}>
          <img
            src={logo}
            style={{
              marginBottom: 15,
              width: width < 600 ? '100%' : '50%',
            }}
            alt='1st class hair studio logo'
          />
          <div>
            <p style={{ margin: 0 }}>Phenix Salon Suites @ Rosedale Commons</p>
            <p style={{ margin: 0 }}>2480 Fairview Avenue North</p>
            <p style={{ margin: 0 }}>Roseville, MN 55113</p>
            <p style={{ margin: '20px 0' }}>Salon Suite Number: 109</p>
            <p style={{ margin: '0 0 10px 0', fontWeight: 'bold' }}>
              By Appointment Only
            </p>
            <p
              style={{
                margin: '0 0 10px 0',
                fontWeight: 'bold',
                fontStyle: 'italic',
              }}
            >
              For Your 1st Class Appointment:
            </p>
            <p style={{ margin: 0 }}>
              Mobile/Text:{' '}
              {isMobile ? (
                <a href='tel:6512718618'>1.651.271.8618</a>
              ) : (
                <span>1.651.271.8618</span>
              )}
            </p>
            <p style={{ margin: 0 }}>
              Email:{' '}
              <a href='mailto:Toany@1stClassHairStudio.com'>
                Toany@1stClassHairStudio.com
              </a>
            </p>
            <p style={{ margin: '20px 0' }}>
              Looking Forward to{' '}
              <span style={{ fontStyle: 'italic' }}>Serving You!</span>
            </p>
            <p style={{ margin: '20px 0', fontStyle: 'italic' }}>
              Thanks and Respectfully,
            </p>
            <p style={{ margin: '20px 0' }}>Toany Le</p>
            <img src={Toany} style={{ marginTop: 15 }} alt='Toany Le' />
          </div>
        </div>
        <footer
          style={{
            borderTop: '1px solid #E4B16F',
            color: '#E4B16F',
            marginTop: 20,
            padding: '15px 0',
          }}
        >
          <p style={{ margin: 0 }}>
            Copyright &copy; {copyright}. 1st Class Hair Studio.
          </p>
          <p style={{ margin: 0 }}>All Rights Reserved.</p>
        </footer>
      </div>
    </div>
  );
}

export default App;
